import React from 'react';
import Layout from '@components/layout';
import { useAuth } from '@hooks/useAuth';
import { SystemNotification } from '@components/SystemNotification';

function AppPage(){
    
    const auth = useAuth();

    return (
        <Layout>
            <SystemNotification />
            test
        </Layout>
    );
};

export default AppPage;
