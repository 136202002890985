import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { navigate } from 'gatsby';
import { getUserIdentity } from '@actions';

export function useAuth(){
  const dispatch = useDispatch();
  const user = useSelector(state => state.currentUser);

  useEffect(() => {
      if(!user){
          navigate('/');
      }
  }, [user]);

  useEffect(() => {
      dispatch(getUserIdentity())
      var timer = setInterval(() => {
        console.log('getting identity');
        dispatch(getUserIdentity()) 
      }, 60 * 1000);

      return function(){
          clearInterval(timer);
      };
  }, [dispatch]);
}