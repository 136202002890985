import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames/bind';

import CloseIcon from '../icons/close';
import styles from './SystemNotification.scss';
import { setStatusMessage, STATUS_TYPE_ERROR, STATUS_TYPE_SUCCESS } from '../../actions';

export function SystemNotification(){

  const dispatch = useDispatch();
  const currentStatusMessage = useSelector(state => state.currentStatusMessage);
  
  const dismissOnClick = () => dispatch(setStatusMessage(null, null));

  if(!currentStatusMessage){
    return null;
  }

  const { message, type } = currentStatusMessage;

  const cx = classNames.bind(styles);

  const systemNotificationClass = cx({
    "system__notification": true,
    "system__notification--show": !!message,
    "system__notification--success": type === STATUS_TYPE_SUCCESS,
    "system__notification--error": type === STATUS_TYPE_ERROR
  });

  return (
    <div className={systemNotificationClass}>
      <div className="system__notification__container">{message}</div>
      {type === STATUS_TYPE_ERROR ? 
        <button 
          onClick={dismissOnClick}
          className="system__notification__dismiss"
        >{CloseIcon}</button> : null}
    </div>
  );
}
